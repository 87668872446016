var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.educationalJoiningApplicationsData),function(educationalJoiningApplication,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":educationalJoiningApplication.educationalJoiningApplicationNameCurrent,"description":educationalJoiningApplication.fullCode,"imagePath":educationalJoiningApplication.educationalJoiningApplicationImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.hasJoiningApplicationSubscription)?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'JoiningApplicationSubscriptions',
          params: {
            userStudentToken: '',
            educationalJoiningApplicationToken:
              educationalJoiningApplication.educationalJoiningApplicationToken,
          },
        },"title":_vm.$t('JoiningApplicationSubscriptions.modelName'),"target":_vm.linkTarget}},[_c('img',{attrs:{"src":require("@/assets/images/JoiningApplicationSubscriptions.svg")}})])],1):_vm._e(),_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalJoiningApplicationGetStatement",modifiers:{"EducationalJoiningApplicationGetStatement":true}}],attrs:{"title":_vm.$t('EducationalCategories.getStatement')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet('EducationalJoiningApplicationGetStatement');}}},[_c('img',{attrs:{"src":require("@/assets/images/statement.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet('EducationalJoiningApplicationInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet('EducationalJoiningApplicationQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(
        _vm.checkPrivilege(_vm.hasEducationalJoiningApplicationChangeApprove()) &&
        educationalJoiningApplication.approvalTypeToken ==
          _vm.APPROVAL_TYPE_TOKENS.UnderReview
      )?_c('li',[_c('button',{attrs:{"title":_vm.$t('EducationalJoiningApplications.approve')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet(
            'EducationalJoiningApplicationChangeApproveAccepted'
          );}}},[_c('img',{attrs:{"src":require("@/assets/images/ApprovalTypes.svg")}})])]):_vm._e(),(
        _vm.checkPrivilege(_vm.hasEducationalJoiningApplicationChangeApprove()) &&
        educationalJoiningApplication.approvalTypeToken ==
          _vm.APPROVAL_TYPE_TOKENS.UnderReview
      )?_c('li',[_c('button',{attrs:{"title":_vm.$t('EducationalJoiningApplications.reject')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet(
            'EducationalJoiningApplicationChangeApproveRejected'
          );}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel.svg")}})])]):_vm._e(),(
        _vm.checkPrivilege(_vm.hasEducationalJoiningApplicationEdit()) &&
        educationalJoiningApplication.approvalTypeToken ==
          _vm.APPROVAL_TYPE_TOKENS.UnderReview
      )?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet('EducationalJoiningApplicationUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setEducationalJoiningApplicationData(educationalJoiningApplication);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }