var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'EducationalJoiningApplicationInfo',"size":"xl","headerText":_vm.$t('EducationalJoiningApplications.data'),"headerIcon":_vm.educationalJoiningApplication.icon}},[_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("students.data")))]),_c('span',{staticClass:"my-card-title end"},[(
          _vm.checkPrivilege(_vm.hasStudent()) &&
          _vm.educationalJoiningApplication.userStudentInfoData &&
          _vm.educationalJoiningApplication.userStudentInfoData.userToken
        )?_c('router-link',{attrs:{"to":{
          name: 'Students',
          params: {
            userToken:
              _vm.educationalJoiningApplication.userStudentInfoData.userToken,
          },
        },"title":_vm.$t('students.data')}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})]):_vm._e()],1),(_vm.approvalTypeAccepted)?_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.userStudentInfoData.userNameCurrent,"title":_vm.$t('Users.nameAr'),"imgName":'students.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.educationalJoiningApplication.userStudentInfoData.userPhoneWithCC,"title":_vm.$t('Users.phone'),"imgName":'phone.svg'}})],1):_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.studentNameAr,"title":_vm.$t('Users.nameAr'),"imgName":'students.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.studentNameEn,"title":_vm.$t('Users.nameEn'),"imgName":'students.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.studentNameUnd,"title":_vm.$t('Users.nameUnd'),"imgName":'students.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.studentNationalNumber,"title":_vm.$t('Users.nationalID'),"imgName":'nationalNumber.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.educationalJoiningApplication.studentPhoneWithCC,"title":_vm.$t('Users.phone'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.studentEmail,"title":_vm.$t('Users.email'),"imgName":'email.svg'}})],1)]),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("EducationalPeriods.data")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalPeriodInfoData
            .educationalPeriodNameCurrent,"title":_vm.$t('general.name'),"imgName":'educationalPeriods.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalPeriodInfoData
            .educationalPeriodDurationCurrent,"title":_vm.$t('general.duration'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.educationalJoiningApplication.educationalPeriodInfoData
              .educationalPeriodStartDate,
            _vm.educationalJoiningApplication.educationalPeriodInfoData
              .educationalPeriodStartTime
          ),"title":_vm.$t('from'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.educationalJoiningApplication.educationalPeriodInfoData
              .educationalPeriodEndDate,
            _vm.educationalJoiningApplication.educationalPeriodInfoData
              .educationalPeriodEndTime
          ),"title":_vm.$t('to'),"imgName":'dateAndTime.svg'}})],1)]),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.fullCode,"title":_vm.$t('EducationalJoiningApplications.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.educationalJoiningApplication.joiningData,
            _vm.educationalJoiningApplication.joiningTime
          ),"title":_vm.$t('EducationalJoiningApplications.joiningDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalCategoryData
            .systemComponentsHierarchyData
            .systemComponentsHierarchyNameCurrent,"title":_vm.$t('SystemComponentsHierarchies.educationalActivity'),"imgName":'systemComponentsHierarchies.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalCategoryData
            .systemComponentData.systemComponentNameCurrent,"title":_vm.$t('SystemComponents.educationalActivity'),"imgName":'systemComponents.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalCategoryData
            .educationalCategoryNameCurrent,"title":_vm.$t('EducationalCategories.parent'),"imgName":'EducationalCategories.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData.priceListNameCurrent,"title":_vm.$t('PriceLists.name'),"imgName":'PriceLists.svg'}}),(
          _vm.educationalJoiningApplication.subscriptionTypeToken ==
          _vm.SUBSCRIPTION_TYPE_TOKENS.Package
        )?[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListPackagePriceForClient,"title":_vm.$t('PriceLists.packagePriceForClient'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListPackagePriceInOfferForClient,"title":_vm.$t('PriceLists.packagePriceInOfferForClient'),"imgName":'money.svg'}})]:_vm._e(),(
          _vm.educationalJoiningApplication.subscriptionTypeToken ==
          _vm.SUBSCRIPTION_TYPE_TOKENS.Session
        )?[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListSessionPriceForClient,"title":_vm.$t('PriceLists.sessionPriceForClient'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListSessionPriceInOfferForClient,"title":_vm.$t('PriceLists.sessionPriceInOfferForClient'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.subscriptionRequestSessionsNumber,"title":_vm.$t(
              'EducationalJoiningApplications.subscriptionRequestSessionsNumber'
            ),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
              _vm.educationalJoiningApplication.subscriptionToDate,
              _vm.educationalJoiningApplication.subscriptionToTime
            ),"title":_vm.$t('EducationalJoiningApplications.subscriptionToDateTime'),"imgName":'dateAndTime.svg'}})]:_vm._e(),(
          _vm.educationalJoiningApplication.subscriptionTypeToken ==
          _vm.SUBSCRIPTION_TYPE_TOKENS.Hour
        )?[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListHourPriceForClient,"title":_vm.$t('PriceLists.hourPriceForClient'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.priceListInfoData
              .priceListHourPriceInOfferForClient,"title":_vm.$t('PriceLists.hourPriceInOfferForClient'),"imgName":'money.svg'}})]:_vm._e(),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.subscriptionTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.SubscriptionTypesName'),"imgName":'SubscriptionTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.enrollmentTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.EnrollmentTypesName'),"imgName":'EnrollmentTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalJoiningApplicationDescriptionAr,"title":_vm.$t('EducationalJoiningApplications.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalJoiningApplicationDescriptionEn,"title":_vm.$t('EducationalJoiningApplications.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalJoiningApplicationDescriptionUnd,"title":_vm.$t('EducationalJoiningApplications.descriptionUnd'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalJoiningApplication.educationalJoiningApplicationNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }