var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('DashboardNavbar',{attrs:{"viewType":_vm.generalSetting.educationalJoiningApplications.viewType,"viewTypeStatus":false,"activationTypeStatus":false,"btnAddTitle":_vm.$t('EducationalJoiningApplications.add'),"btnAddName":'EducationalJoiningApplicationAdd',"btnAddStatus":_vm.checkPrivilege(_vm.hasEducationalJoiningApplicationAdd()),"filterSheetName":'filterSheet',"filterSheetStatus":true},on:{"updateViewType":function($event){return _vm.updateViewType('educationalJoiningApplications', $event)}}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[_c('EducationalJoiningApplicationTabs',{attrs:{"educationalJoiningApplicationsStatistics":_vm.educationalJoiningApplications.educationalJoiningApplicationsStatistics,"theFilterData":_vm.educationalJoiningApplications.filterData},on:{"search":function($event){return _vm.search($event)},"currentTabUpdated":function($event){_vm.currentTab = $event}}}),(_vm.hasData)?[(
          _vm.generalSetting.educationalJoiningApplications.viewType ==
          _vm.VIEW_TYPES.table
        )?_c('EducationalJoiningApplicationTable',{attrs:{"educationalJoiningApplicationsData":_vm.educationalJoiningApplications.educationalJoiningApplicationsData,"defaultImg":_vm.educationalJoiningApplications.educationalJoiningApplication
            .defaultImg,"filterData":_vm.educationalJoiningApplications.filterData,"currentTab":_vm.currentTab},on:{"setEducationalJoiningApplicationData":function($event){return _vm.educationalJoiningApplications.educationalJoiningApplication.fillData(
            $event
          )},"statementStatus":function($event){_vm.statementStatus = $event}}}):(
          _vm.generalSetting.educationalJoiningApplications.viewType ==
          _vm.VIEW_TYPES.cards
        )?_c('EducationalJoiningApplicationCards',{attrs:{"educationalJoiningApplicationsData":_vm.educationalJoiningApplications.educationalJoiningApplicationsData,"defaultImg":_vm.educationalJoiningApplications.educationalJoiningApplication
            .defaultImg,"filterData":_vm.educationalJoiningApplications.filterData},on:{"setEducationalJoiningApplicationData":function($event){return _vm.educationalJoiningApplications.educationalJoiningApplication.fillData(
            $event
          )}}}):_vm._e(),_c('ActionsData',{attrs:{"actionsData":_vm.educationalJoiningApplications.educationalJoiningApplication}}),_c('EducationalJoiningApplicationInfo',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication}}),_c('EducationalJoiningApplicationDelete',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication},on:{"refresh":function($event){return _vm.getAllEducationalJoiningApplications()}}}),_c('EducationalJoiningApplicationChangeApproveAccepted',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication},on:{"refresh":function($event){return _vm.getAllEducationalJoiningApplications()},"isLoading":function($event){_vm.isLoading = $event}}}),_c('EducationalJoiningApplicationChangeApproveRejected',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication},on:{"refresh":function($event){return _vm.getAllEducationalJoiningApplications()},"isLoading":function($event){_vm.isLoading = $event}}}),_c('CustomBottomSheetQRCode',{attrs:{"refName":'EducationalJoiningApplicationQRCode',"code":_vm.educationalJoiningApplications.educationalJoiningApplication
            .fullCode,"codeTitle":_vm.$t('EducationalJoiningApplications.code'),"name":_vm.educationalJoiningApplications.educationalJoiningApplication
            .educationalJoiningApplicationNameCurrent,"nameTitle":_vm.$t('EducationalJoiningApplications.name'),"nameIcon":'EducationalJoiningApplications.svg'}}),_c('EducationalJoiningApplicationReportFilter',{attrs:{"token":_vm.educationalJoiningApplications.educationalJoiningApplication
            .educationalJoiningApplicationToken,"statementStatus":_vm.statementStatus},on:{"isLoading":function($event){_vm.isLoading = $event}}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(_vm.hasData)?_c('CustomPagination',{attrs:{"paginationData":_vm.educationalJoiningApplications.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}}):_vm._e(),_c('EducationalJoiningApplicationFilter',{attrs:{"theFilterData":_vm.educationalJoiningApplications.filterData},on:{"search":function($event){return _vm.search($event)}}}),_c('EducationalJoiningApplicationAdd',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication},on:{"refresh":function($event){return _vm.getAllEducationalJoiningApplications()}}}),_c('EducationalJoiningApplicationUpdate',{attrs:{"educationalJoiningApplication":_vm.educationalJoiningApplications.educationalJoiningApplication},on:{"refresh":function($event){return _vm.getAllEducationalJoiningApplications()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }